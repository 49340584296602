<template>
  <div class="asset-grid-wrapper">
    <masonry :cols="{ default: 4, 991: 2, 767: 1 }" :gutter="{ default: '30px' }" class="asset-grid">
      <AssetItem
        v-for="(asset, index) in paginatedAssets"
        :key="index"
        :asset="asset"
        :class="'asset-' + index"
        ref="assets"
        @user-favorite="assetFavorited($event, index)"
      />
    </masonry>
    <p v-if="filteredAssets.length === 0">No assets to display with the currently selected filters</p>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import AssetItem from '@/components/AssetItem.vue';

export default {
  components: {
    AssetItem,
  },
  data() {
    return {
      activeFilters: [],
    };
  },
  props: {
    sortBy: {
      type: String,
      default: 'asc',
    },
    displayLimit: {
      type: Number,
      default: 12,
    },
  },
  computed: {
    ...mapGetters(['assets', 'filters']),
    page() {
      const page = Number.parseInt(this.$route.params.page, 10);
      if (Number.isNaN(page)) {
        return 1;
      }
      return page;
    },
    isFavoritesPage() {
      return this.$route.path.includes('/favorites');
    },
    filteredAssets() {
      let { assets } = this;
      if (this.activeFilters.length > 0 && this.assets.length > 0) {
        // Go through each filter by title
        // create array for that title
        // loop thru each parameter
        // compare all arrays
        const assetsToCompare = [];
        this.activeFilters.forEach((filter) => {
          assetsToCompare[filter.title] = [];
          this.assets.forEach((asset) => {
            for (let index = 0; index < filter.parameters.length; index += 1) {
              const parameter = filter.parameters[index];
              if (parameter.isActive) {
                if (
                  filter.title === 'Asset Type' &&
                  asset.assetType.name === parameter.name &&
                  assetsToCompare[filter.title].indexOf(asset) === -1
                ) {
                  assetsToCompare[filter.title].push(asset);
                }
                if (
                  filter.title === 'Asset Category' &&
                  asset.assetCategories.some((category) => category.name === parameter.name) &&
                  assetsToCompare[filter.title].indexOf(asset) === -1
                ) {
                  assetsToCompare[filter.title].push(asset);
                }
                if (
                  filter.title === 'Job Role' &&
                  asset.assetJobRoleCategories.some((role) => role.name === parameter.name) &&
                  assetsToCompare[filter.title].indexOf(asset) === -1
                ) {
                  assetsToCompare[filter.title].push(asset);
                }
                if (
                  filter.title === 'Model Year' &&
                  asset.assetModelYear.modelYear === parameter.name &&
                  assetsToCompare[filter.title].indexOf(asset) === -1
                ) {
                  assetsToCompare[filter.title].push(asset);
                }
                if (
                  filter.title === 'Model' &&
                  asset.assetModelYear.modelName === parameter.name &&
                  assetsToCompare[filter.title].indexOf(asset) === -1
                ) {
                  assetsToCompare[filter.title].push(asset);
                }
              }
            }
          });
        });
        // Add first set of filtered assets to main array
        const firstKey = Object.keys(assetsToCompare)[0];
        assets = assetsToCompare[firstKey];
        if (Object.keys(assetsToCompare).length > 1) {
          assets.forEach((asset) => {
            // For each next filtered asset set, compare to main array
            // If not matched, remove it
            Object.keys(assetsToCompare).forEach((key, index) => {
              // Skip the first set (already in array)
              if (index > 0 && assetsToCompare[key].length > 0) {
                if (assetsToCompare[key].indexOf(asset) === -1) {
                  assets = assets.filter((thisAsset) => thisAsset !== asset);
                }
              } else if (assetsToCompare[key].length === 0) {
                assets = [];
              }
            });
          });
        }
      }
      if (assets.length > 0) {
        // If on Favorites Page
        if (this.isFavoritesPage) {
          assets = assets.filter((asset) => asset.assetFavoriteFlag === 1);
        }
        // Sort
        if (this.sortBy === 'asc') {
          assets.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        } else {
          assets.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        }
      }
      return assets;
    },
    paginatedAssets() {
      const offset = (this.page - 1) * this.displayLimit;
      return this.filteredAssets.slice(offset).slice(0, this.displayLimit);
    },
  },
  mounted() {
    this.calculateActiveFilters();
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'SET_ASSETS') {
        this.calculateActiveFilters();
      }
    });
    if (this.filteredAssets.length === 0) {
      this.$router.push({ name: this.$router.name, params: { page: 1 } });
    }
  },
  beforeDestroy() {
    this.$store.commit('SET_FILTERS_TO_DEFAULT');
    this.unsubscribe();
  },
  watch: {
    sortBy(newVal) {
      if (newVal === 'asc') {
        this.filteredAssets.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      } else {
        this.filteredAssets.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      }
    },
    filteredAssets: {
      handler(newAssets) {
        this.$emit('filtered-asset-length', newAssets.length);
      },
      deep: true,
    },
    paginatedAssets: {
      handler() {
        if (this.paginatedAssets.length === 0 && this.filteredAssets !== 0) {
          this.$router.replace({ name: this.$router.name, params: { page: 1 } });
        }
      },
      deep: true,
    },
  },
  methods: {
    assetFavorited(favorite, index) {
      const asset = this.filteredAssets[index];
      axios
        .post(`${process.env.VUE_APP_API_URL}/users/favorite`, {
          assetId: asset.id,
          isFavorite: favorite ? 1 : 0,
        })
        .then(() => {
          this.filteredAssets[index].assetFavoriteFlag = favorite ? 1 : 0;
        });
    },
    calculateActiveFilters() {
      const filters = JSON.parse(JSON.stringify(this.filters));
      this.activeFilters = filters.filter((filter) => {
        let isActive = false;
        filter.parameters.forEach((parameter) => {
          if (parameter.isActive) {
            isActive = true;
          }
        });
        if (isActive) {
          return true;
        }
        return false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.asset-grid-wrapper {
  width: 100%;
  .asset-grid {
    width: 100%;
    padding: 1.875rem 0.625rem; // 30px 10px
    @include bp-md-tablet {
      max-width: 660px;
      padding-right: 0;
      padding-left: 0;
    }
    @include bp-lg-laptop {
      max-width: none;
    }
    .asset {
      margin-bottom: 30px;
    }
  }
}
</style>
