<template>
  <div class="carousel-container">
    <router-link v-if="showEdit" class="edit-container" to="/portal/cms/dashboard/edit-slider">
      <EditIcon />
    </router-link>
    <Carousel
      :class="`${container}`"
      autoplay
      :autoPlayTimeout="5000"
      ease="ease-out"
      :perPage="1"
      paginationActiveColor="#333"
      paginationColor="#e5e5e5"
      :loop="true"
    >
      <Slide v-for="slide in sliderAssets" :key="slide.id" :class="`${container}__slide`">
        <picture>
          <img :src="slide.presignedBackgroundImage" :alt="slide.text" />
        </picture>
        <span :class="`${container}__text`">{{ slide.text }}</span>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import EditIcon from '@/assets/icons/system_edit.svg';

export default {
  name: 'slider',
  components: {
    Carousel,
    Slide,
    EditIcon,
  },
  props: {
    showEdit: Boolean,
    sliderContent: {
      type: Array,
    },
  },
  data() {
    return {
      container: 'slider',
      staticContent: [
        // {
        //   id: 1,
        //   text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
        //   presignedBackgroundImage: '/img/slider_1@2x.png',
        // },
        // {
        //   id: 2,
        //   text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
        //   presignedBackgroundImage: '/img/slider_2@2x.png',
        // },
        // {
        //   id: 3,
        //   text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
        //   presignedBackgroundImage: '/img/slider_3@2x.png',
        // },
        // {
        //   id: 4,
        //   text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
        //   presignedBackgroundImage: '/img/slider_4@2x.png',
        // },
        {
          id: 5,
          text: 'Welcome to the Audi Accessories Portal',
          presignedBackgroundImage: '/img/slider_5@2x.png',
        },
      ],
    };
  },
  created() {},
  computed: {
    sliderAssets() {
      return this.sliderContent?.length ? this.sliderContent : this.staticContent;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.carousel-container {
  position: relative;
  .edit-container {
    position: absolute;
    top: 44px;
    right: 44px;
    z-index: 100;
    svg {
      width: 44px;
      height: 44px;
      background: $black;
      border-radius: 50%;
      .cls-1 {
        fill: $grey--soft;
      }
      &:hover {
        background: $grey--dark;
        .cls-1 {
          fill: $white;
        }
      }
    }
  }
  .slider {
    .slider__slide {
      position: relative;
      width: 100%;
      overflow: hidden;
      img {
        width: 150%;
        height: auto;
        margin-left: -25%;
        @include bp-md-tablet {
          width: 100%;
          margin-left: 0;
        }
      }
      &::after {
        position: absolute;
        top: 0;
        z-index: 50;
        display: block;
        width: 100%;
        height: 50%;
        content: '';
        background-image: linear-gradient(to bottom, $black, rgba(255, 255, 255, 0));
        opacity: 0.5;
      }
    }
    &__text {
      position: absolute;
      top: 0;
      left: 0;
      z-index: $show-in-front-1;
      width: 100%;
      height: 100%;
      padding: 1.5rem; // 24px
      color: white;
      @include slider-text;
      @include bp-md-tablet {
        padding: 2rem; // 32px
      }
    }
  }
  ::v-deep .VueCarousel-pagination {
    position: absolute;
    bottom: 1rem; // 16px
    @include bp-md-tablet {
      bottom: 2rem; // 32px
    }

    button.VueCarousel-dot {
      // colors passed as props above and pulled from indicator SVG files
      // !important to override child component styles that are inline
      width: 0.75rem !important; // 12px
      height: 0.3125rem !important; // 5px
      padding: 0 !important;
      margin: 0 0.125rem !important; // 2px
      border-radius: 0;
      &:focus {
        outline: 0;
      }
      @include bp-md-tablet {
        width: 1.25rem !important; // 20px
        height: 0.375rem !important; // 6px
      }
    }
  }
}
</style>
