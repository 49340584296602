<template>
  <div id="app">
    <SiteNavigation v-if="!isCms" />
    <CmsNavigationBar v-else />
    <div class="page" :class="{ cms: isCms }">
      <CmsHeader v-if="isCms" />
      <transition name="fade" mode="out-in">
        <router-view :key="pathWithPageRemoved" />
      </transition>
      <footer class="footer" :class="{ cms: isCms }">
        <div class="content">
          <p>Audi of America. All rights reserved.</p>
          <p>&copy; Copyright {{ currentYear }}. Confidential. Internal use only.</p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SiteNavigation from '@/components/SiteNavigation.vue';
import CmsNavigationBar from '@/components/CmsNavigationBar.vue';
import CmsHeader from '@/components/CmsHeader.vue';

export default {
  components: {
    SiteNavigation,
    CmsHeader,
    CmsNavigationBar,
  },
  computed: {
    ...mapGetters(['userAccess']),
    isCms() {
      return this.$route.path.includes('/cms/');
    },
    noRerender() {
      return this.$route.path.includes('/dashboard/');
    },
    currentYear() {
      return new Date().getFullYear();
    },
    pathWithPageRemoved() {
      if (this.avoidRerender) return '';
      const pageIndex = this.$route.path.search(this.$route.params.page);
      return pageIndex ? this.$route.path.slice(0, pageIndex - 1) : this.$route.path;
    },
  },
};
</script>

<style lang="scss">
// Allow element/type selectors, because this is global CSS.
@import '@/assets/scss/reboot';
@import '~plyr/src/sass/plyr';
@import '@/assets/scss/buttons';
@import '@/assets/scss/forms';
@import '@/assets/scss/typography';
@import '@/assets/scss/breakpoints';
@import '@/assets/scss/global';
@import '@/assets/scss/modal-global';
@import '@/assets/scss/assets';
@import '../node_modules/vue-loading-overlay/dist/vue-loading.css';

.page {
  box-sizing: border-box;
  &.cms {
    width: calc(100% - 275px) !important;
    padding-top: 0;
    margin-left: 275px;
    color: $grey--dark;
    background: $white;
  }
}

footer.cms {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 105px;
  margin: 0 40px;
  border-top: 1px solid $dividers;
  .content {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* #app {
  overflow-y: scroll;
} */
</style>
