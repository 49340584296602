<template>
  <div class="filters-modal">
    <BaseAccordionMobile
      v-for="(filter, index) in filters"
      :key="filter.title"
      :class="['filter-' + index, { isCms: isCms }]"
    >
      <template v-if="showFilter(filter)" v-slot:header>{{ filter.title }}</template>
      <template v-if="showFilter(filter)" v-slot:content>
        <div v-if="filter.isCheckbox">
          <div class="form-check" v-for="parameter in filter.parameters" :key="parameter.value">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="parameter.isActive"
              :id="`check-${parameter.value}`"
              @change="updateFilters($event, parameter.value, filter.title)"
            />
            <label class="form-check-label" :for="`check-${parameter.value}`"> {{ parameter.name }} </label>
          </div>
        </div>
        <div v-else>
          <BaseDropdown
            :btnText="filter.selectionText"
            :dropdownOptions="filter.parameters"
            @selection-clicked="updateDropdownFilters($event, filter.title)"
          />
        </div>
      </template>
    </BaseAccordionMobile>
    <div class="btn-container">
      <button class="btn btn-primary" @click="applyFilters">Apply Filters</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseAccordionMobile from '@/components/BaseAccordionMobile.vue';
import BaseDropdown from '@/components/BaseDropdown.vue';

export default {
  components: {
    BaseAccordionMobile,
    BaseDropdown,
  },
  props: {
    isCms: Boolean,
  },
  computed: {
    ...mapGetters(['filters']),
    isFavoritesPage() {
      return this.$route.path.includes('/favorites');
    },
  },
  created() {
    if (this.isFavoritesPage || this.isCms) {
      // The favorites page should not have any filters set on load
      this.$store.commit('SET_FILTERS_TO_BLANK');
      this.unsubscribe = this.$store.subscribe((mutation) => {
        // On reload, we need to wait to set filters to blank
        if (mutation.type === 'SET_DEFAULT_FILTERS') {
          this.$store.commit('SET_FILTERS_TO_BLANK');
        }
      });
    }
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  methods: {
    updateFilters(e, parameterValue, filterTitle) {
      const checkbox = e.target;
      this.$store.commit('SET_FILTERS', { checkbox, parameterValue, filterTitle });
    },
    updateDropdownFilters(e, filterTitle) {
      this.$store.commit('SET_FILTERS_DROPDOWN', { parameterValue: e.value, filterTitle });
    },
    applyFilters() {
      this.$emit('apply-filters');
    },
    showFilter(filter) {
      return !this.isCms || (this.isCms && filter.title !== 'Asset Category');
    },
  },
};
</script>

<style lang="scss" scoped>
.filters-modal {
  position: relative;
  flex-grow: 1;
  padding-bottom: 120px;
  @include bp-lg-laptop {
    position: static;
    display: grid;
    grid-template-areas:
      'type role year .'
      'type role model .'
      'category category btn .';
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 150px 1fr 1fr;
    gap: 0 0;
    min-height: 0;
    padding: 0;
  }
  .form-check {
    margin-bottom: 0.9375rem; // 15px
  }
  .filter-0 {
    grid-area: type;
  }
  .filter-1 {
    grid-area: category;
    &.isCms {
      display: none;
    }
    ::v-deep .accordion-body > div {
      @include bp-lg-laptop {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
  }
  .filter-2 {
    grid-area: role;
    ::v-deep .accordion-body > div {
      @include bp-lg-laptop {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
  }
  .filter-3 {
    grid-area: year;
    @include bp-lg-laptop {
      padding-left: 2.5rem; // 40px
      border-left: 1px solid $grey--light;
    }
    ::v-deep .dropdown button {
      width: 100%;
      max-width: none;
    }
  }
  .filter-4 {
    grid-area: model;
    @include bp-lg-laptop {
      padding-left: 2.5rem; // 40px
      border-left: 1px solid $grey--light;
    }
    ::v-deep .dropdown button {
      width: 100%;
      max-width: none;
    }
  }
  .btn-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 1.875rem 1.25rem; // 30px 20px
    @include bp-lg-laptop {
      position: static;
      display: flex;
      grid-area: btn;
      align-items: flex-end;
      padding-right: 0;
      padding-bottom: 0;
      padding-left: 2.5rem; // 40px
      text-align: right;
      border-left: 1px solid $grey--light;
    }
    .btn {
      display: block;
      width: 100%;
      font-family: $wide;
      @include bp-lg-laptop {
        display: inline-block;
        width: auto;
        margin-left: auto;
      }
    }
  }
}
</style>
