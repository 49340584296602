<template>
  <div class="asset-image" :class="{ 'asset-image--placeholder': type !== 'Image' }">
    <template v-if="type === 'Image'">
      <img :src="asset.downloadLink" :alt="asset.name" @load="imageLoaded" crossorigin="anonymous" />
    </template>
    <template v-else>
      <IconPDF v-if="type === 'PDF'" />
      <IconMP3 v-if="type === 'MP3'" />
      <IconVideo v-if="type === 'Video'" />
    </template>
  </div>
</template>

<script>
import IconPDF from '@/assets/icons/pdf_asset.svg';
import IconMP3 from '@/assets/icons/mp3_asset.svg';
import IconVideo from '@/assets/icons/video_asset.svg';

export default {
  components: {
    IconPDF,
    IconMP3,
    IconVideo,
  },
  props: {
    asset: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    if (this.type !== 'Image') {
      this.imageLoaded();
    }
  },
  computed: {
    type() {
      return this.asset.assetType.name;
    },
    typeClass() {
      return `asset--type--${this.asset.assetType.name.toLowerCase()}`;
    },
  },
  methods: {
    imageLoaded() {
      this.$emit('image-loaded');
    },
  },
};
</script>

<style lang="scss" scoped>
.asset-image {
  img {
    width: 100%;
    height: auto;
  }
  &--placeholder {
    width: 100%;
    height: 169px;
    padding: 50px 75px;
    svg {
      display: block;
      width: 100%;
      height: 100%;
      margin-right: auto;
      margin-left: auto;
    }
    .asset--type--pdf &,
    .asset-full-type--pdf & {
      background-color: $grey--dark;
    }
    .asset--type--pdf & svg {
      max-width: 60px;
      height: 75px;
    }
    .asset--type--mp3 &,
    .asset-full-type--mp3 & {
      background-color: $grey--warm;
    }
    .asset--type--mp3 & svg {
      max-width: 79px;
      height: 60px;
    }
    .asset--type--video &,
    .asset-full-type--video & {
      background-color: $black;
    }
    .asset--type--video & {
      background-color: $black;
      svg {
        max-width: 103px;
        height: 59px;
      }
    }
    .asset-page & {
      min-height: 185px;
      @include bp-md-tablet {
        min-height: 235px;
        padding: 65px 100px;
      }
    }
  }
}
</style>
