<template>
  <div class="header">
    <h2>{{ routeName }}</h2>
    <div class="right-container">
      <div class="user">
        <DefaultUserIcon class="user-icon" />
        <span>{{ user.uid }}</span>
      </div>
      <div class="container"><span class="logout" @click="logoutUser">Log Out</span></div>
      <SiteNavigationSearch class="search-container" />
    </div>
  </div>
</template>

<script>
import DefaultUserIcon from '@/assets/icons/user_icon.svg';
import { mapGetters } from 'vuex';
import SiteNavigationSearch from '@/components/SiteNavigationSearch.vue';

export default {
  name: 'cms-header',
  components: {
    DefaultUserIcon,
    SiteNavigationSearch,
  },
  computed: {
    ...mapGetters(['user']),
    routeName() {
      return this.$route.name?.split('CMS ')[1] || this.$route.name;
    },
  },
  methods: {
    logoutUser() {
      this.$store.dispatch('logoutUser');
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  color: $grey--dark;
  background: $white;
  border-bottom: 1px solid $dividers;
  h2 {
    margin-bottom: 0;
    margin-left: 40px;
    font-size: 32px;
  }
  .right-container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 425px;
    height: 100%;
    padding-right: 78px;
    font-size: 12px;
    .user {
      display: flex;
      align-items: center;
      margin-right: 20px;
      font-family: $extended;
      font-weight: bold;
      .user-icon {
        width: 30px;
        height: 30px;
        margin: 16px;
      }
    }
    .container {
      padding-right: 20px;
      border-right: 1px solid $grey--light;
      .logout {
        padding: 10px;
        font-family: $extended;
        color: $grey--medium;
        cursor: pointer;
      }
    }
    .search-container {
      border-left: none;
      ::v-deep {
        form {
          border-left: none;
        }
        button:focus {
          outline: none;
        }
        input:focus {
          outline: none;
        }
      }
    }
  }
}
</style>
