<template>
  <nav aria-label="page navigation">
    <ul class="pagination">
      <li class="page-item" :class="{ disabled: page === 1 }">
        <router-link v-if="page !== 1" class="page-link" :to="url + previousPage">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.061 12.707">
            <path d="M6.707.354l-6 6 6 6" fill="none" />
          </svg>
          <span class="sr-only">Previous</span>
        </router-link>
        <span v-else>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.061 12.707">
            <path d="M6.707.354l-6 6 6 6" fill="none" />
          </svg>
          <span class="sr-only">Previous</span>
        </span>
      </li>
      <li class="page-item" v-for="(pageNumber, index) in pages" :key="index" :class="{ active: page === pageNumber }">
        <router-link class="page-link" :to="url + pageNumber">
          {{ pageNumber }} <span class="sr-only" v-if="page === pageNumber">(current)</span>
        </router-link>
      </li>
      <li class="page-item" :class="{ disabled: page === pages.length }">
        <router-link v-if="page !== pages.length" class="page-link" :to="url + nextPage">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.061 12.707">
            <path d="M.353.354l6 6-6 6" fill="none" />
          </svg>
          <span class="sr-only">Next</span>
        </router-link>
        <span v-else>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.061 12.707">
            <path d="M.353.354l6 6-6 6" fill="none" />
          </svg>
          <span class="sr-only">Next</span>
        </span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    perPage: {
      type: Number,
      default: 12,
    },
    itemCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    /**
     * Get router path with params stripped off
     */
    url() {
      if (this.$route.path === '/') {
        return '/page/';
      }
      let trimmedPath = this.$route.path;
      Object.values(this.$route.params).forEach((param) => {
        const foundIndex = trimmedPath.search(param);
        if (foundIndex !== -1) {
          trimmedPath = this.$route.path.slice(0, foundIndex);
        }
      });
      return trimmedPath || `${this.$route.path}/`;
    },
    page() {
      const page = Number.parseInt(this.$route.params.page, 10);
      if (Number.isNaN(page)) {
        return 1;
      }
      return page;
    },
    nextPage() {
      return this.page + 1;
    },
    previousPage() {
      return this.page - 1;
    },
    pages() {
      const pages = [];
      const numberOfPages = Math.ceil(this.itemCount / this.perPage);
      for (let index = 1; index <= numberOfPages; index += 1) {
        pages.push(index);
      }
      if (numberOfPages === 0) {
        pages.push(1);
      }
      return pages;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: center;
  padding: 0;
  li {
    display: block;
    height: 30px;
    padding: 0 5px;
    text-align: center;
    > a,
    > span {
      display: block;
      padding: 5px;
      font-size: 0.9375rem; // 15px
      line-height: 30px;
      color: $grey--medium;
      text-decoration: none;
      border-bottom: 2px solid transparent;
    }
    &.active,
    &.router-link-active {
      > a,
      > span {
        color: $black;
        border-color: $black;
      }
    }
    &.disabled > a,
    &.disabled > span {
      color: $grey--medium;
      cursor: default;
    }
    i {
      font-size: 2rem;
    }
    svg {
      width: 1em;
      height: 1em;
      stroke: currentColor;
    }
  }
}
</style>
