import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import ContentLibrary from '../views/ContentLibrary.vue';

Vue.use(VueRouter);

function checkAuth(to, from, next) {
  // We have a valid token
  if (store.getters.isTokenValid) {
    const isNavToCms = to.path.includes('/cms/') && store.getters.user.admin;
    const requests = isNavToCms
      ? [store.dispatch('getConfigs'), store.dispatch('getEnums'), store.dispatch('getCmsAssets')]
      : [store.dispatch('getAssets'), store.dispatch('getConfigs')];
    Promise.allSettled(requests).finally(() => {
      if (!store.getters.user.admin && to.path.includes('/cms/')) {
        next({ path: '/' });
      } else {
        next();
      }
    });
    // We do not have a token but have a 24g_id query param
  } else if (to.query['24g_id']) {
    store.dispatch('loginUser', { twenty4gId: to.query['24g_id'], app: store.getters.currentApp }).then((user) => {
      Promise.allSettled([store.dispatch('getAssets'), store.dispatch('getConfigs')]).finally(() => {
        if (!user.admin && to.path.includes('cms')) {
          next({ path: '/' });
        } else {
          next({ ...to, query: {} });
        }
      });
    });
    // We have no token or 24g_id query param, clear tokens and redirect to audi portal
  } else {
    store.dispatch('clearTokenAndRedirect');
  }
}

const routes = [
  {
    path: '/',
    name: 'Content Library',
    component: ContentLibrary,
    beforeEnter: checkAuth,
  },
  {
    path: '/page/:page?',
    name: 'Content Library Pages',
    props: (route) => {
      const page = Number.parseInt(route.params.page, 10);
      if (Number.isNaN(page)) {
        return 1;
      }
      return { page };
    },
    component: ContentLibrary,
    beforeEnter: checkAuth,
  },
  {
    path: '/asset/submit',
    name: 'Submit Asset',
    component: () => import(/* webpackChunkName: "about" */ '../views/SubmitAsset.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/asset/:assetId?',
    name: 'Asset',
    props: (route) => {
      const assetId = Number.parseInt(route.params.assetId, 10);
      if (Number.isNaN(assetId)) {
        return 1;
      }
      return { assetId };
    },
    // route level code-splitting
    // this generates a separate chunk (metrics.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Asset.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/directory',
    name: 'Directory',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Directory.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/feedback',
    name: 'Feedback',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Feedback.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/favorites/:page?',
    name: 'Favorites Page',
    props: (route) => {
      let page = Number.parseInt(route.params.page, 10);
      if (Number.isNaN(page)) {
        page = 1;
      }
      const isFavoritesPage = true;
      return { page, isFavoritesPage };
    },
    component: ContentLibrary,
    beforeEnter: checkAuth,
  },
  {
    path: '/portal/cms/dashboard',
    component: () => import(/* webpackChunkName "cms-dashboard" */ '../views/CmsDashboard.vue'),
    beforeEnter: checkAuth,
    children: [
      {
        name: 'CMS Dashboard',
        path: '/',
        redirect: 'pending',
      },
      {
        name: 'CMS Dashboard',
        path: 'pending/:page?',
        component: () =>
          import(/* webpackChunkName "cms-dealer-submitted-assets" */ '../views/CmsDealerSubmittedAssets.vue'),
      },
      {
        name: 'CMS Dashboard',
        path: 'approved/:page?',
        component: () =>
          import(/* webpackChunkName "cms-dealer-submitted-assets" */ '../views/CmsDealerSubmittedAssets.vue'),
      },
      {
        name: 'CMS Dashboard',
        path: 'denied/:page?',
        component: () =>
          import(/* webpackChunkName "cms-dealer-submitted-assets" */ '../views/CmsDealerSubmittedAssets.vue'),
      },
      {
        name: 'CMS Edit Slider',
        path: 'edit-slider',
        component: () => import(/* webpackChunkName "cms-edit-slider" */ '../views/CmsEditSliderForm.vue'),
      },
    ],
  },
  {
    path: '/portal/cms/audi-collection/:page?',
    name: 'Audi Collection',
    component: () => import(/* webpackChunkName "cms-collection" */ '../views/CmsBaseAssetCollection.vue'),
    meta: { categoryId: 2 },
    beforeEnter: checkAuth,
  },
  {
    path: '/portal/cms/merchandising/:page?',
    name: 'CMS Merchandising',
    component: () => import(/* webpackChunkName "cms-merchandising" */ '../views/CmsBaseAssetCollection.vue'),
    meta: { categoryId: 1 },
    beforeEnter: checkAuth,
  },
  {
    path: '/portal/cms/incentives/:page?',
    name: 'CMS Incentives',
    component: () => import(/* webpackChunkName "cms-incentives" */ '../views/CmsBaseAssetCollection.vue'),
    meta: { categoryId: 3 },
    beforeEnter: checkAuth,
  },
  {
    path: '/portal/cms/product-information/:page?',
    name: 'CMS Product Information',
    component: () => import(/* webpackChunkName "cms-product-info" */ '../views/CmsBaseAssetCollection.vue'),
    meta: { categoryId: 5 },
    beforeEnter: checkAuth,
  },
  {
    path: '/portal/cms/sell-sheets/:page?',
    name: 'CMS Sell Sheets',
    component: () => import(/* webpackChunkName "cms-sell-sheets" */ '../views/CmsBaseAssetCollection.vue'),
    meta: { categoryId: 6 },
    beforeEnter: checkAuth,
  },
  {
    path: '/portal/cms/asset/:assetId?',
    name: 'CMS Asset',
    props: (route) => {
      const assetId = Number.parseInt(route.params.assetId, 10);
      if (Number.isNaN(assetId)) {
        return 1;
      }
      return { assetId };
    },
    component: () => import(/* webpackChunkName: "cms-asset" */ '../views/Asset.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/portal/cms/add-asset',
    name: 'Add Asset',
    component: () => import(/* webpackChunkName: "cms-add-asset" */ '../views/SubmitAsset.vue'),
    props: true,
    beforeEnter: checkAuth,
  },
  {
    path: '/portal/cms/asset/edit/:assetId',
    name: 'Edit Asset',
    component: () => import(/* webpackChunkName: "cms-edit-asset" */ '../views/CmsEditAsset.vue'),
  },
  // {
  //   path: '/portal/cms/templates',
  //   name: 'CMS Templates',
  //   component: () => import(/* webpackChunkName "cms-templates" */ '../views/CmsMasonryLayout.vue'),
  //   beforeEnter: checkAuth,
  // },
  {
    path: '/portal/cms/directory',
    name: 'CMS Directory',
    component: () => import(/* webpackChunkName "cms-directory" */ '../views/CmsDirectory.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/portal/cms/feedback',
    name: 'CMS Feedback',
    component: () => import(/* webpackChunkName "cms-feedback" */ '../views/Feedback.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/portal/cms',
    redirect: '/portal/cms/dashboard/pending',
  },
  {
    path: '*',
    redirect: (to) => {
      if (to.path.includes('/cms/')) {
        return '/portal/cms';
      }
      return '/';
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    // Scroll to the top of the page on route navigation
    if (to.path.includes('dashboard') && from.path.includes('dashboard')) return savedPosition;
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
