// eslint-disable-next-line
import ApiService from '@/services/api.service';
import jwtDecode from 'jwt-decode';

export default {
  state: {
    userAccess: null,
    user: null,
  },
  getters: {
    userAccess: (state) => state.userAccess,
    user: (state) => state.user,
    isTokenValid: (state) => state.userAccess && new Date() < new Date(state.user?.exp * 1000),
  },
  actions: {
    loginUser: ({ commit, dispatch }, { twenty4gId }) =>
      new Promise((resolve, reject) => {
        ApiService.get('/users/login', { id: twenty4gId })
          .then((response) => {
            const { token } = response.data.data;
            commit('SET_USER', token);
            resolve(jwtDecode(token));
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              dispatch('clearTokenAndRedirect');
            }
            reject(error.response);
          });
      }),
    clearTokenAndRedirect({ commit }) {
      commit('LOGOUT_USER');
      window.location.href = `https://vwgoalogin-audi.track360.com/?APP_ID=LEGACY&callback=${window.location.href}`;
    },
    logoutUser({ commit }) {
      commit('LOGOUT_USER');
      window.location.href = 'https://vwgoalogin-audi.track360.com/logout.php';
    },
  },
  mutations: {
    SET_USER: (state, userAccess) => {
      ApiService.setToken(userAccess);
      state.userAccess = userAccess;
      state.user = jwtDecode(userAccess);
    },
    LOGOUT_USER: (state) => {
      ApiService.setToken(null);
      state.userAccess = null;
      state.user = null;
    },
  },
};
