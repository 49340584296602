<template>
  <section :class="`${container}`">
    <Slider v-if="!isFavoritesPage" :sliderContent="sliderContent" />
    <div class="content">
      <header class="header">
        <h1>{{ !isFavoritesPage ? 'Content Library' : 'Favorites' }}</h1>
        <router-link to="/asset/submit" class="btn btn-outline-primary btn-submit-asset">Submit New Asset</router-link>
        <FilterButton class="btn-filter" @button-clicked="openFilters" />
        <BaseDropdown
          :btnPreText="'Sort by: '"
          :btnText="sortBy"
          :dropdownOptions="sortByOptions"
          @selection-clicked="updateSortBy($event)"
        />
        <BaseDropdown
          :btnText="displayLimit"
          :dropdownOptions="displayLimitOptions"
          @selection-clicked="updateDisplayLimit($event)"
        />
      </header>
      <div class="asset-grid-container">
        <AssetGrid
          ref="assetGrid"
          :sortBy="sortByValue"
          :displayLimit="displayLimitValue"
          @filtered-asset-length="setPages($event)"
        />
      </div>
      <AssetPagination v-if="filteredAssetLength !== 0" :itemCount="filteredAssetLength" :perPage="displayLimitValue" />
    </div>
    <BaseModal
      ref="filtersModal"
      :title="'Filters'"
      :modalType="'filter'"
      @modal-open="filtersModalOpened"
      @modal-close="filtersModalClosed"
    >
      <template v-slot:modal-content>
        <FilterModal @apply-filters="applyFilters" />
      </template>
    </BaseModal>
  </section>
</template>

<script>
import Slider from '@/components/Slider.vue';
import FilterButton from '@/components/FilterButton.vue';
import BaseDropdown from '@/components/BaseDropdown.vue';
import AssetGrid from '@/components/AssetGrid.vue';
import BaseModal from '@/components/BaseModal.vue';
import FilterModal from '@/components/FilterModal.vue';
import AssetPagination from '@/components/AssetPagination.vue';
import ApiService from '@/services/api.service';

export default {
  name: 'content-library',
  components: {
    Slider,
    FilterButton,
    BaseDropdown,
    AssetGrid,
    BaseModal,
    FilterModal,
    AssetPagination,
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    isFavoritesPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      container: 'Content-Library',
      sortByValue: 'asc',
      sortBy: 'Recently Added',
      sortByOptions: [
        {
          value: 'asc',
          name: 'Recently Added',
        },
        {
          value: 'desc',
          name: 'Least Recently Added',
        },
      ],
      displayLimit: 'Show 12',
      displayLimitValue: 12,
      displayLimitOptions: [
        {
          value: 12,
          name: 'Show 12',
        },
        {
          value: 24,
          name: 'Show 24',
        },
        {
          value: 36,
          name: 'Show 36',
        },
        {
          value: 48,
          name: 'Show 48',
        },
      ],
      filteredAssetLength: 0,
      sliderContent: [],
    };
  },
  created() {
    this.$store.commit('SET_FAVORITES_PAGE_BREADCRUMB', this.isFavoritesPage);
    if (!this.isFavoritesPage) {
      ApiService.get('/admin/slider/').then(({ data }) => {
        this.sliderContent = data?.data?.slider;
      });
    }
  },
  watch: {
    $route(to, from) {
      if (!from.path.includes('/favorites') && to.path.includes('/favorites')) {
        this.$store.commit('SET_FILTERS_TO_BLANK');
        this.$store.commit('SET_FAVORITES_PAGE_BREADCRUMB', true);
      } else if (from.path.includes('/favorites') && !to.path.includes('/favorites')) {
        this.$store.commit('SET_FILTERS_TO_DEFAULT');
        this.$store.commit('SET_FAVORITES_PAGE_BREADCRUMB', false);
      }
      this.$refs.assetGrid.calculateActiveFilters();
    },
  },
  methods: {
    openFilters() {
      this.$refs.filtersModal.openModal();
    },
    applyFilters() {
      this.$refs.assetGrid.calculateActiveFilters();
      this.$refs.filtersModal.hideModal();
      this.$router.replace({ name: this.$router.name, params: { page: 1 } });
    },
    filtersModalOpened() {
      // Keeping function for future use
    },
    filtersModalClosed() {
      // Keeping function for future use
    },
    updateSortBy(event) {
      this.sortBy = event.name;
      this.sortByValue = event.value;
      this.$router.replace({ name: this.$router.name, params: { page: 1 } });
    },
    updateDisplayLimit(event) {
      this.displayLimit = event.name;
      this.displayLimitValue = event.value;
      this.$router.replace({ name: this.$router.name, params: { page: 1 } });
    },
    setPages(event) {
      this.filteredAssetLength = event;
    },
  },
};
</script>

<style lang="scss" scoped>
header.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  h1 {
    @include bp-lg-laptop {
      width: 100%;
    }
  }
  .btn-submit-asset {
    @include bp-lg-laptop {
      order: 4;
      margin-left: auto;
    }
  }
  .btn-filter {
    @include bp-lg-laptop {
      max-width: 165px;
      margin-right: 1.875rem; // 30px
    }
  }
  .dropdown {
    @include bp-lg-laptop {
      margin-right: 1.875rem; // 30px
    }
  }
}
.asset-grid-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
