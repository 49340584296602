import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import store from '@/store/index';

const ApiService = {
  init() {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    // axios.defaults.headers.common['Accept-Language'] = localStorage.getItem('language') || 'en';
    this.setToken(localStorage.getItem('auth-token'));
  },

  setToken(token) {
    if (token) {
      axios.defaults.headers.common.Authorization = token;
      localStorage.setItem('auth-token', token);
    } else delete axios.defaults.headers.common.Authorization;
  },

  get(endpoint, params) {
    return axios.get(endpoint, { params }).catch((error) => {
      if (error?.response?.status === 401) {
        store.dispatch('clearTokenAndRedirect');
      }
    });
  },

  putS3(presignedUrl, fileData) {
    const instance = axios.create();
    const type = fileData.split(';')[0].split(':')[1];
    const buffer = Buffer.from(fileData.replace(/^data:image\/\w+;base64,/, ''), 'base64');

    instance.defaults.headers['Content-Type'] = type;
    instance.defaults.headers['Content-Encoding'] = 'base64';
    delete instance.defaults.headers.common.Authorization;
    return instance.put(presignedUrl, buffer);
  },

  patch(endpoint, params) {
    return axios.patch(endpoint, params).catch((error) => {
      if (error?.response?.status === 401) {
        store.dispatch('clearTokenAndRedirect');
      }
    });
  },

  post(endpoint, params, queryParams) {
    return axios.post(endpoint, params, queryParams).catch((error) => {
      if (error?.response?.status === 401) {
        store.dispatch('clearTokenAndRedirect');
      }
    });
  },

  put(endpoint, params) {
    return axios.put(endpoint, params).catch((error) => {
      if (error?.response?.status === 401) {
        store.dispatch('clearTokenAndRedirect');
      }
    });
  },

  delete(endpoint, params) {
    return axios.delete(endpoint, params).catch((error) => {
      if (error?.response?.status === 401) {
        store.dispatch('clearTokenAndRedirect');
      }
    });
  },
};

export default ApiService;
