<template>
  <div class="asset" :class="typeClass">
    <div class="asset--container">
      <AssetThumbnail :asset="asset" @image-loaded="imageLoaded" />
      <div class="asset--content" :class="{ 'bg-grey': isCms }">
        <h2>{{ asset.name }}</h2>
        <div class="asset--tags">
          <div class="asset-tag">{{ asset.assetModelYear.modelName }}</div>
          <div class="asset-tag">{{ asset.assetModelYear.modelYear }}</div>
          <div class="asset-tag">{{ type }}</div>
        </div>
        <div class="asset--misc">
          <div>
            <strong>Job Roles</strong>
            <div>
              <span v-for="(jobRole, index) in asset.assetJobRoleCategories" :key="jobRole.id">
                {{ jobRole.name }}<span v-if="index !== asset.assetJobRoleCategories.length - 1">,</span>
              </span>
            </div>
          </div>
          <div>
            <strong>Category</strong>
            <div>
              <span v-for="(category, index) in asset.assetCategories" :key="category.id">
                {{ category.name }}<span v-if="index !== asset.assetCategories.length - 1">,</span>
              </span>
            </div>
          </div>
        </div>
        <div class="asset--btns">
          <button v-if="!isCms" class="btn btn-favorite" :class="{ active: isFavorite }" @click="postFavorite">
            <IconFavorite v-if="!isFavorite" />
            <IconFavoriteActive v-if="isFavorite" />
            <span class="sr-only">Favorite</span>
          </button>
          <router-link v-if="isCms" :to="`/portal/cms/asset/edit/${asset.id}`" class="btn btn-view-more">
            <IconEdit /><span class="sr-only">View More</span>
          </router-link>
          <div v-if="asset.isDownloadEnabled" class="download-container">
            <AssetDownloadButton
              v-if="type === 'Image' || type === 'PDF'"
              :downloadLink="asset.downloadLink"
              :isCircle="true"
            />
            <AssetMp3Button
              v-if="type === 'MP3'"
              :downloadLink="asset.downloadLink"
              :name="asset.name"
              :isCircle="true"
            />
            <AssetVideoButton v-if="type === 'Video'" :downloadLink="asset.downloadLink" :isCircle="true" />
          </div>
          <router-link :to="isCms ? `/portal/cms/asset/${asset.id}` : '/asset/' + asset.id" class="btn btn-view-more"
            ><IconMore /><span class="sr-only">View More</span></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssetDownloadButton from '@/components/AssetDownloadButton.vue';
import AssetMp3Button from '@/components/AssetMp3Button.vue';
import AssetVideoButton from '@/components/AssetVideoButton.vue';
import AssetThumbnail from '@/components/AssetThumbnail.vue';
import IconFavorite from '@/assets/icons/system_favorite.svg';
import IconFavoriteActive from '@/assets/icons/system_favorite_active.svg';
import IconMore from '@/assets/icons/system_more.svg';
import IconEdit from '@/assets/icons/system_edit.svg';

export default {
  components: {
    AssetDownloadButton,
    AssetMp3Button,
    AssetVideoButton,
    AssetThumbnail,
    IconFavorite,
    IconFavoriteActive,
    IconMore,
    IconEdit,
  },
  props: {
    asset: {
      type: Object,
      default: () => {},
    },
    isCms: Boolean,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    type() {
      return this.asset.assetType.name;
    },
    typeClass() {
      return `asset--type--${this.asset.assetType.name.toLowerCase()}`;
    },
    isFavorite: {
      get() {
        return this.asset.assetFavoriteFlag;
      },
      // setter
      set(newValue) {
        this.$emit('user-favorite', newValue);
      },
    },
  },
  methods: {
    imageLoaded() {
      this.$emit('image-loaded');
    },
    postFavorite() {
      this.isFavorite = !this.isFavorite;
    },
  },
};
</script>

<style lang="scss" scoped>
.asset {
  align-self: start;
  width: 100%;
  max-width: 300px;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  background-color: $white;
  &--content {
    padding: 0.9375rem; // 15px
    color: $grey--dark;
    &.bg-grey {
      background: $grey-background;
    }
    h2 {
      margin-bottom: 0.9375rem; // 15px
      font-family: $extended;
      font-size: 0.875rem; // 14px;
      font-weight: normal;
    }
  }
  &--tags {
    display: flex;
    margin-bottom: 0.9375rem; // 15px
    > div {
      margin-right: 0.3125rem;
    }
  }
  &--misc > div {
    display: flex;
    margin-bottom: 0.625rem; // 10px
    font-size: 0.75rem; // 12px
    strong {
      min-width: 58px;
    }
  }
  &--btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.3125rem; // 5px
    ::v-deep .btn {
      width: 44px;
      height: 44px;
      padding: 0;
      margin-left: 0.3125rem; // 5px
      border: 0;
      border-radius: 50%;
      transition: all 0.3s ease;
      &:hover {
        color: $grey--medium;
      }
      svg {
        width: 100%;
        height: auto;
        fill: currentColor;
      }
    }
  }
}
</style>
