<template>
  <div class="content--image modal-template">
    <div class="modal-trigger" @click="openModal">
      <slot name="trigger"></slot>
    </div>
    <div
      ref="baseModal"
      class="modal fade"
      :class="[{ show: isShown }, 'modal-' + modalType]"
      :style="{ display: isOpen ? displayStyle : 'none' }"
      tabindex="-1"
      aria-hidden="true"
      @click="modalClick($event)"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title">{{ title }}</h2>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="hideModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <slot name="modal-content"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'base-modal',
  props: {
    title: {
      type: String,
      default: '',
    },
    modalType: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      isOpen: false,
      isShown: false, // When animations are complete
    };
  },
  computed: {
    modalElement() {
      return this.$refs.baseModal;
    },
    dialogDuration() {
      const modalDialog = this.modalElement.querySelector('.modal-dialog');
      return this.getTransitionDurationFromElement(modalDialog);
    },
    displayStyle() {
      return this.modalType !== 'default' ? 'flex' : 'block';
    },
  },
  methods: {
    openModal() {
      this.isOpen = true;
      // Add class to body
      document.body.classList.add('modal-open');
      // document.body.style.paddingRight = '15px'
      // Append to body
      document.body.appendChild(this.modalElement);
      // Create backdrop
      let backdrop = document.getElementById('base-modal-backdrop');
      if (backdrop === null) {
        backdrop = document.createElement('div');
        backdrop.id = 'base-modal-backdrop';
        backdrop.className = 'modal-backdrop fade show';
        document.body.appendChild(backdrop);
      }
      // Animation
      setTimeout(() => {
        this.isShown = true;
      }, this.dialogDuration);
      // Emit Event
      this.$emit('modal-open');
    },
    hideModal() {
      this.isShown = false;
      // Remove class from body
      document.body.classList.remove('modal-open');
      // document.body.style = ''
      // Destroy backdrop
      const backdrop = document.getElementById('base-modal-backdrop');
      if (backdrop !== null) document.body.removeChild(backdrop);
      // Animation
      setTimeout(() => {
        this.isOpen = false;
      }, this.dialogDuration);
      // Emit Event
      this.$emit('modal-close');
    },
    modalClick(event) {
      if (event.target.closest('.modal-content')) return false;
      this.hideModal();
      return true;
    },
    getTransitionDurationFromElement(element) {
      if (!element) {
        return 0;
      }

      // Get transition-duration of the element
      let { transitionDuration, transitionDelay } = window.getComputedStyle(element);

      const floatTransitionDuration = Number.parseFloat(transitionDuration);
      const floatTransitionDelay = Number.parseFloat(transitionDelay);

      // Return 0 if element or transition duration is not found
      if (!floatTransitionDuration && !floatTransitionDelay) {
        return 0;
      }

      // If multiple durations are defined, take the first
      transitionDuration = transitionDuration.split(',')[0];
      transitionDelay = transitionDelay.split(',')[0];

      return (Number.parseFloat(transitionDuration) + Number.parseFloat(transitionDelay)) * 1000;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  ::v-deep .dropdown .dropdown-menu {
    z-index: 1100;
  }
}
.modal.fade {
  transition: opacity 0.15s linear;
}
.modal.fade:not(.show) {
  opacity: 0;
}
.modal-dialog {
  position: relative;
  width: 100%;
  max-width: 780px;
  min-height: 100vh;
  margin-right: auto;
  margin-left: auto;
  // allow clicks to pass through for custom click handling to close modal
  pointer-events: none;
  @include bp-lg-laptop {
    min-height: 0;
    margin-top: 2rem; // 32px
    margin-bottom: 2rem; // 32px
  }
  // When fading in the modal, animate it to slide down
  .modal.fade & {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
  }
  .modal.show & {
    transform: none;
  }
}

// Actual modal
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
  min-height: 100vh;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  @include bp-lg-laptop {
    min-height: 0;
  }
}
.modal-body {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: calc(100% - 62px);
  > * {
    width: 100%;
  }
  @include bp-lg-laptop {
    padding: 2.5rem; // 40px
  }
}

.modal-header {
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 49px;
  background-color: $black;
  .modal-title {
    flex-grow: 1;
    width: 100vw;
    padding-top: 1.25rem; // 20px
    padding-bottom: 1.25rem; // 20px
    font-family: $wide;
    font-size: 0.75rem; // 12px
    font-weight: normal;
    color: $white;
    text-align: center;
    @include bp-lg-laptop {
      width: 100%;
      padding: 0.9375rem 1.5625rem; // 15px 25px
      margin: 0;
      font-size: 1rem; // 16px
      text-align: left;
    }
  }
  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin-top: 1.25rem; // 20px
    margin-right: 1.25rem; // 20px
    margin-bottom: 1.25rem; // 20px
    &::before,
    &::after {
      background-color: $white;
    }
  }
}
.modal-trigger {
  cursor: pointer;
}

// Video and MP3 Modals
.modal-video,
.modal-mp3 {
  .modal-dialog {
    width: calc(100% - 2.5rem); // 40px
    min-height: 0;
    margin-right: 1.25rem; // 20px
    margin-left: 1.25rem; // 20px
  }
  .modal-content {
    min-height: 0;
  }
}
.modal-video {
  .modal-dialog {
    max-width: 900px;
    @media (min-width: 940px) {
      margin-top: 2rem; // 32px
      margin-right: auto;
      margin-bottom: 2rem; // 32px
      margin-left: auto;
    }
  }
  .modal-body {
    padding: 0;
  }
}
.modal-mp3 {
  .modal-dialog {
    max-width: 600px;
    @media (min-width: 640px) {
      margin-top: 2rem; // 32px
      margin-right: auto;
      margin-bottom: 2rem; // 32px
      margin-left: auto;
    }
  }
  .modal-body {
    padding: 2.5rem 3.125rem 2.5rem 1.125rem; // 40px 50px 40px 18px
    @include bp-md-tablet {
      padding-left: 2.5rem; // 40px
    }
  }
}

// Report Modal
.modal-report {
  .modal-dialog {
    width: calc(100% - 2.5rem); // 40px
    max-width: 600px;
    min-height: 0;
    margin-right: 1.25rem; // 20px
    margin-left: 1.25rem; // 20px
    @media (min-width: 640px) {
      margin-top: 2rem; // 32px
      margin-right: auto;
      margin-bottom: 2rem; // 32px
      margin-left: auto;
    }
  }
  .modal-content {
    min-height: 0;
  }
  .modal-header {
    background-color: $white;
  }
  .btn-close::before,
  .btn-close::after {
    background-color: $black;
  }
  .modal-body {
    padding: 0 1.875rem 1.875rem 1.875rem; // 30px
  }
}
</style>
