// eslint-disable-next-line import/no-cycle
import ApiService from '@/services/api.service';

export default {
  state: {
    assets: {},
    cmsAssets: [],
  },
  getters: {
    assets: (state) => state.assets,
    cmsAssets: (state) => state.cmsAssets,
  },
  actions: {
    getAssets({ state, commit }, forceRefresh) {
      if (!state.assets.length || forceRefresh) {
        return ApiService.get('/assets').then((response) => {
          commit('SET_ASSETS', response.data.data.assets);
        });
      }
      return Promise.resolve();
    },
    getCmsAssets({ state, commit }, forceRefresh) {
      if (!state.cmsAssets.length || forceRefresh) {
        return ApiService.get('/admin/assets/all').then(({ data }) => {
          commit('SET_CMS_ASSETS', data.data.assets);
        });
      }
      return Promise.resolve();
    },
  },
  mutations: {
    SET_ASSETS: (state, assets) => {
      state.assets = assets;
    },
    SET_CMS_ASSETS: (state, cmsAssets) => {
      state.cmsAssets = cmsAssets;
    },
  },
};
