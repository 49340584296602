/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import assets from './modules/assets';
import filters from './modules/filters';
import user from './modules/user';
import metadata from './modules/metadata';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['user'],
    }),
  ],
  state: {
    // userAccess: '',
    /* user: {
      username: 'iraIsSoCool',
    }, */
    search: {
      active: false,
      dropDownActive: false,
      query: '',
    },
    config: {
      modelYears: [],
      jobRoles: [],
      categories: [],
      feedbackDepartments: [],
    },
    isFavoritesPageBreadcrumb: false,
  },
  getters: {
    config: (state) => state.config,
    isFavoritesPageBreadcrumb: (state) => state.isFavoritesPageBreadcrumb,
  },
  mutations: {
    logoutUserAccess(state) {
      state.userAccess = '';
    },
    openSearchDropDown(state) {
      state.search.dropDownActive = true;
    },
    toggleSearch(state) {
      const {
        search: { active, dropDownActive },
      } = state;
      // 1 click opens search bar, 2nd click opens list, 3rd click closes
      if (active && dropDownActive) {
        state.search.dropDownActive = false;
        state.search.active = false;
      } else if (active && !dropDownActive) {
        state.search.dropDownActive = true;
      } else if (!active && !dropDownActive) {
        state.search.active = true;
      }
    },
    SET_CONFIG_MODEL_YEARS: (state, modelYears) => {
      state.config.modelYears = modelYears;
    },
    SET_CONFIG_JOB_ROLES: (state, jobRoles) => {
      state.config.jobRoles = jobRoles;
    },
    SET_CONFIG_CATEGORIES: (state, categories) => {
      state.config.categories = categories;
    },
    SET_CONFIG_DEPARTMENTS: (state, departments) => {
      state.config.feedbackDepartments = departments;
    },
    SET_FAVORITES_PAGE_BREADCRUMB: (state, isFavoritesPage) => {
      state.isFavoritesPageBreadcrumb = isFavoritesPage;
    },
  },
  actions: {},
  modules: {
    assets,
    filters,
    user,
    metadata,
  },
});
