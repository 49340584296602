<template>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button"
        :class="{ collapsed: !isOpen }"
        type="button"
        aria-expanded="false"
        @click="toggle"
      >
        <slot name="header"></slot>
        <IconCarat class="icon" />
      </button>
    </h2>
    <transition
      name="accordion"
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
      v-on:before-leave="beforeLeave"
      v-on:leave="leave"
    >
      <div class="accordion-collapse" v-show="isOpen" :class="{ show: isOpen }">
        <div class="accordion-body">
          <slot name="content"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import IconCarat from '@/assets/icons/carat_down.svg';

export default {
  components: {
    IconCarat,
  },
  data() {
    return {
      isOpen: false,
      visibleWidth: 991,
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    toggle() {
      if (window.innerWidth < this.visibleWidth) {
        this.isOpen = !this.isOpen;
      } else {
        this.isOpen = true;
      }
    },
    beforeEnter(el) {
      el.style.height = '0';
    },
    enter(el) {
      el.style.height = `${el.scrollHeight}px`;
    },
    beforeLeave(el) {
      el.style.height = `${el.scrollHeight}px`;
    },
    leave(el) {
      el.style.height = '0';
    },
    handleResize() {
      this.isOpen = window.innerWidth > this.visibleWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion-item {
  color: $grey--dark;
}
.accordion-collapse {
  overflow: hidden;
  background-color: #fff;
  border-top: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  transition: 150ms ease-out;
  &.show {
    overflow: visible;
  }
}
.accordion-header {
  margin-bottom: 0;
}
.accordion-button {
  display: flex;
  width: 100%;
  padding: 1.25rem; // 20px
  font-family: $extended;
  font-size: 0.75rem; // 12px
  color: $grey--dark;
  text-align: left;
  background-color: $white;
  border: 1px solid transparent;
  border-bottom-color: $grey--light;
  .icon {
    width: 1em;
    height: 1em;
    margin-left: auto;
    color: $black;
    stroke: currentColor;
    transition: 150ms ease-out;
  }
  &:not(.collapsed) .icon {
    transform: rotate(180deg);
  }
  @include bp-lg-laptop {
    padding: 0;
    margin-bottom: 1.25rem; // 20px
    font-size: 0.875rem; // 14px;
    cursor: text;
    border-bottom-color: transparent;
    .icon {
      display: none;
    }
  }
}
.accordion-body {
  padding: 1.25rem 1.875rem; // 20px 30px
  font-size: 0.875rem; // 14px;
  overflow-wrap: break-word;
  background-color: $grey-background;
  @include bp-lg-laptop {
    padding: 0;
    background-color: $white;
  }
}
</style>
