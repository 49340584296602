<template>
  <div class="btn-modal-container">
    <button class="btn btn-play" @click="openMp3Modal">
      <IconPlayCircle v-if="isCircle" /><IconPlay v-else /><span :class="{ 'sr-only': isCircle }">Play MP3</span>
    </button>
    <BaseModal ref="mp3Modal" :modalType="'mp3'" @modal-open="mp3ModalOpened" @modal-close="mp3ModalClosed">
      <template v-slot:modal-content>
        <div class="mp3">
          <VuePlyr ref="mp3Plyr" :options="mp3Options">
            <audio controls controlsList="nodownload" playsinline>
              <source :src="downloadLink" type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </VuePlyr>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from '@/components/BaseModal.vue';
import IconPlayCircle from '@/assets/icons/system_play.svg';
import IconPlay from '@/assets/icons/play.svg';
import VuePlyr from 'vue-plyr';

export default {
  components: {
    BaseModal,
    IconPlayCircle,
    IconPlay,
    VuePlyr,
  },
  props: {
    downloadLink: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    isCircle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mp3Options: {
        controls: `
          <div class="plyr__controls">
            <button type="button" class="plyr__control icon-play" aria-label="Play, {title}" data-plyr="play">
              <svg class="icon--pressed" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><rect class="cls-1" x="19" y="15" width="1" height="15"/><path class="cls-1" d="M22.5,0A22.5,22.5,0,1,0,45,22.5,22.52,22.52,0,0,0,22.5,0Zm0,44A21.5,21.5,0,1,1,44,22.5,21.53,21.53,0,0,1,22.5,44Z"/><rect class="cls-1" x="25" y="15" width="1" height="15"/></g></g></svg>
              <svg class="icon--not-pressed" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M22.5,0A22.5,22.5,0,1,0,45,22.5,22.52,22.52,0,0,0,22.5,0Zm0,44A21.5,21.5,0,1,1,44,22.5,21.53,21.53,0,0,1,22.5,44Z"/><path class="cls-1" d="M18,30.55l11.79-8.16L18,14.46Zm1-14.21,9,6.07-9,6.24Z"/></g></g></svg>
              <span class="label--pressed plyr__tooltip" role="tooltip">Pause</span>
              <span class="label--not-pressed plyr__tooltip" role="tooltip">Play</span>
            </button>
            <div class="plyr__info-container">
              <div class="plyr__info">{title}</div>
              <div class="plyr__progress">
                <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
                <progress class="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
                <span role="tooltip" class="plyr__tooltip">00:00</span>
              </div>
              <div class="plyr__time-container">
                <div class="plyr__time plyr__time--current" aria-label="Current time">00:00</div>
                <div class="plyr__time plyr__time--duration" aria-label="Duration">00:00</div>
              </div>
            </div>
          </div>`,
        settings: ['captions'],
        displayDuration: true,
        toggleInvert: false,
        volume: 1,
        keyboard: {
          focused: false,
          global: false,
        },
        hideControls: false,
        title: this.name,
      },
    };
  },
  methods: {
    openMp3Modal() {
      this.$refs.mp3Modal.openModal();
    },
    mp3ModalOpened() {
      // Keeping for future use
    },
    mp3ModalClosed() {
      this.$refs.mp3Plyr.player.stop();
    },
  },
};
</script>

<style lang="scss" scoped>
.mp3 {
  ::v-deep {
    audio {
      width: 100%;
    }
    .plyr--audio .plyr__control.plyr__tab-focus,
    .plyr--audio .plyr__control:hover,
    .plyr--audio .plyr__control[aria-expanded='true'] {
      color: $grey--dark;
      background-color: transparent;
    }
    .plyr__controls {
      justify-content: flex-start;
      width: 100%;
    }
    .icon-play {
      padding: 0 0.9375rem 0 0; // 15px
      svg {
        width: 2.8125rem; // 45px
        height: 2.8125rem; // 45px
      }
    }
    .plyr__info-container {
      flex-grow: 1;
      text-align: left;
    }
    .plyr__time + .plyr__time {
      display: block;
      &::before {
        margin-right: 5px;
        margin-left: 5px;
        content: '|';
      }
    }
    .plyr__time-container {
      display: flex;
    }
  }
}
</style>
