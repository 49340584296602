<template>
  <div class="btn-modal-container">
    <button class="btn btn-play" @click="openVideoModal">
      <IconPlayCircle v-if="isCircle" /><IconPlay v-else /><span :class="{ 'sr-only': isCircle }">Play video</span>
    </button>
    <BaseModal ref="videoModal" :modalType="'video'" @modal-open="videoModalOpened" @modal-close="videoModalClosed">
      <template v-slot:modal-content>
        <div class="video">
          <VuePlyr ref="videoPlyr" :options="videoOptions">
            <video controls>
              <source :src="downloadLink" type="video/mp4" />
            </video>
          </VuePlyr>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from '@/components/BaseModal.vue';
import IconPlayCircle from '@/assets/icons/system_play.svg';
import IconPlay from '@/assets/icons/play.svg';
import VuePlyr from 'vue-plyr';

export default {
  components: {
    BaseModal,
    IconPlayCircle,
    IconPlay,
    VuePlyr,
  },
  props: {
    downloadLink: {
      type: String,
      default: '',
    },
    isCircle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      videoOptions: {
        controls: `
          <div class="plyr__controls">
            <button type="button" class="plyr__control icon-play" aria-label="Play, {title}" data-plyr="play">
              <svg class="icon--pressed" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><rect class="cls-1" x="19" y="15" width="1" height="15"/><path class="cls-1" d="M22.5,0A22.5,22.5,0,1,0,45,22.5,22.52,22.52,0,0,0,22.5,0Zm0,44A21.5,21.5,0,1,1,44,22.5,21.53,21.53,0,0,1,22.5,44Z"/><rect class="cls-1" x="25" y="15" width="1" height="15"/></g></g></svg>
              <svg class="icon--not-pressed" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M22.5,0A22.5,22.5,0,1,0,45,22.5,22.52,22.52,0,0,0,22.5,0Zm0,44A21.5,21.5,0,1,1,44,22.5,21.53,21.53,0,0,1,22.5,44Z"/><path class="cls-1" d="M18,30.55l11.79-8.16L18,14.46Zm1-14.21,9,6.07-9,6.24Z"/></g></g></svg>
              <span class="label--pressed plyr__tooltip" role="tooltip">Pause</span>
              <span class="label--not-pressed plyr__tooltip" role="tooltip">Play</span>
            </button>
            <div class="plyr__progress">
                <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
                <progress class="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
                <span role="tooltip" class="plyr__tooltip">00:00</span>
            </div>
            <div class="plyr__time-container">
            <div class="plyr__time plyr__time--current" aria-label="Current time">00:00</div>
            <div class="plyr__time plyr__time--duration" aria-label="Duration">00:00</div>
            </div>
            <div class="mobile-break"></div>
            <div class="plyr__volume-container">
              <button type="button" class="plyr__control" aria-label="Mute" data-plyr="mute">
                  <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-muted"></use></svg>
                  <svg class="icon--not-pressed" role="presentation" xmlns="http://www.w3.org/2000/svg" width="20.018" height="18.459" viewBox="0 0 20.018 18.459">
                    <g id="volume_icon" transform="translate(-5 17.434)">
                      <g id="Group_2942" data-name="Group 2942" transform="translate(5.5 -16.5)">
                        <path id="Path_26288" data-name="Path 26288" d="M5,11.207h6.31V19.62H5Zm6.31,8.413,6.31,4V7l-6.31,4.207m9.886-.841s6.31,3.786,0,10.1" transform="translate(-5 -7)" fill="none" stroke="#666" stroke-width="1"/>
                      </g>
                    </g>
                  </svg>
                  <span class="label--pressed plyr__tooltip" role="tooltip">Unmute</span>
                  <span class="label--not-pressed plyr__tooltip" role="tooltip">Mute</span>
              </button>
              <div class="plyr__volume">
                  <input data-plyr="volume" type="range" min="0" max="1" step="0.05" value="1" autocomplete="off" aria-label="Volume">
              </div>
            </div>
          </div>`,
        settings: ['captions'],
        displayDuration: true,
        toggleInvert: false,
        volume: 1,
        keyboard: {
          focused: false,
          global: false,
        },
        hideControls: false,
      },
    };
  },
  methods: {
    openVideoModal() {
      this.$refs.videoModal.openModal();
    },
    videoModalOpened() {
      // Keeping for future use
    },
    videoModalClosed() {
      this.$refs.videoPlyr.player.stop();
    },
  },
};
</script>

<style lang="scss" scoped>
.video {
  ::v-deep {
    video {
      width: 100%;
    }
    .mobile-break {
      flex-basis: 100%;
      height: 0;
      @include bp-md-tablet {
        display: none;
      }
    }
    .plyr--video .plyr__control.plyr__tab-focus,
    .plyr--video .plyr__control:hover,
    .plyr--video .plyr__control[aria-expanded='true'] {
      color: $grey--dark;
      background-color: transparent;
    }
    .plyr--video .plyr__controls {
      position: relative;
      flex-wrap: wrap;
      justify-content: flex-start;
      order: 2;
      width: 100%;
      padding: 0.9375rem; // 15px
    }
    .icon-play {
      padding: 0 0.9375rem 0 0; // 15px
      svg {
        width: 2.8125rem; // 45px
        height: 2.8125rem; // 45px
      }
    }
    .plyr__progress {
      width: calc(100% - 80px);
      @include bp-md-tablet {
        flex-grow: 1;
        order: 2;
        width: auto;
      }
    }
    .icon-volume svg {
      width: 1.1875rem; // 19px
      height: 1rem; // 16px
    }
    .plyr__time + .plyr__time {
      display: block;
      &::before {
        margin-right: 5px;
        margin-left: 5px;
        content: '|';
      }
    }
    .plyr__time--current {
      color: $black;
    }
    .plyr__time-container {
      display: flex;
      margin-left: 60px;
      @include bp-md-tablet {
        margin-right: 0.9375rem; // 15px
        margin-left: 0;
      }
    }
    .plyr__volume-container {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      .plyr__volume {
        width: 100px;
        min-width: 100px;
      }
      @include bp-md-tablet {
        order: 3;
        margin-left: 2.5rem; // 40px
      }
    }
  }
}
</style>
