<template>
  <nav class="nav-bar">
    <router-link to="/portal/cms/dashboard"
      ><AudiCmsLogo width="137" height="137" alt="audi portal logo"
    /></router-link>
    <router-link
      v-for="location in locations"
      :key="location.name"
      :to="location.link"
      :class="{ active: $route.path.includes(location.link) }"
      >{{ location.name }}</router-link
    >
  </nav>
</template>

<script>
import AudiCmsLogo from '../assets/icons/audi_cms_logo.svg';

export default {
  name: 'cms-navigation-bar',
  title: 'Audi Accessories Portal CMS',
  components: {
    AudiCmsLogo,
  },
  data() {
    return {
      locations: [
        { name: 'Dashboard', link: '/portal/cms/dashboard' },
        { name: 'Audi Collection', link: '/portal/cms/audi-collection' },
        { name: 'Merchandising', link: '/portal/cms/merchandising' },
        { name: 'Incentives', link: '/portal/cms/incentives' },
        { name: 'Product Information', link: '/portal/cms/product-information' },
        { name: 'Sell Sheets', link: '/portal/cms/sell-sheets' },
        { name: 'Directory', link: '/portal/cms/directory' },
        { name: 'Feedback', link: '/portal/cms/feedback' },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.nav-bar {
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  max-width: 275px;
  height: 100vh;
  padding: 0 25px;
  overflow-y: auto;
  color: $white;
  background: $black;
  a {
    padding: 15px 0;
    font-family: $normal;
    color: $grey--soft;
    text-decoration: none;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(204, 204, 204, 0.2);
    }
    &.active {
      font-weight: bold;
      color: $white;
    }
    &:hover {
      color: $white;
    }
  }
}
</style>
