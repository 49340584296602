<template>
  <div class="Site-Navigation-Links">
    <ul>
      <li
        class="Site-Navigation-Links__link"
        :class="{
          'Site-Navigation-Links__link--active': currentRoutePath === '/' || currentRoutePath.includes('/asset/'),
        }"
      >
        <router-link to="/">Content Library</router-link>
      </li>
      <li
        class="Site-Navigation-Links__link"
        :class="{ 'Site-Navigation-Links__link--active': currentRoutePath === '/directory' }"
      >
        <router-link to="/directory">Directory</router-link>
      </li>
      <li
        class="Site-Navigation-Links__link"
        :class="{ 'Site-Navigation-Links__link--active': currentRoutePath === '/feedback' }"
      >
        <router-link to="/feedback">Feedback</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  computed: {
    currentRoutePath() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.Site-Navigation-Links {
  flex-grow: 1;

  @include bp-md-tablet {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
  }
  @include bp-lg-laptop {
    width: auto;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    @include bp-md-tablet {
      justify-content: flex-start;
    }
  }
  .Site-Navigation-Links__link {
    flex: 1 0 0%;
    font-family: $extended;
    font-size: 0.75rem; // 12px;
    text-align: center;
    letter-spacing: -0.24px;
    a {
      display: block;
      padding: 0.9375rem 0.5rem; // 15px 8px
      color: $grey--medium;
      text-align: center;
      text-decoration: none;
      @include bp-md-tablet {
        padding: 0.9375rem 0.9375rem 1.5rem 0.9375rem; // 15px 24px
        white-space: nowrap;
      }
    }
    &--active {
      background: $grey-background;
      a {
        color: $black;
      }
    }
    @include bp-md-tablet {
      flex-grow: 0;
      margin-top: 1.25rem; // 20px
      font-size: 0.875rem; // 14px;
    }
  }
}
</style>
