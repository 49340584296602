<template>
  <ul :class="`${container}`">
    <li :class="`${container}__result`" v-for="(asset, index) in results" :key="index">
      <router-link :to="isCms ? `/portal/cms/asset/${asset.id}` : `/asset/${asset.id}`">
        <div :class="`${container}__title`">{{ asset.name }}</div>
        <div :class="`${container}__tags`">
          <span :class="`${container}__tag`">{{ asset.assetModelYear.modelName }}</span>
          <span :class="`${container}__tag`">{{ asset.assetModelYear.modelYear }}</span>
          <span :class="`${container}__tag`">{{ asset.assetType.name }}</span>
        </div>
      </router-link>
    </li>
    <li :class="`${container}__no-result`" v-if="results.length === 0">
      We couldn't find anything matching your query.
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'site-navigation-search-results',
  components: {},
  props: {
    query: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      container: 'Site-Navigation-Search-Results',
      content: '',
    };
  },
  computed: {
    ...mapGetters(['assets', 'cmsAssets']),
    isCms() {
      return this.$route.path.includes('/cms/');
    },
    results() {
      let assets = this.isCms ? this.cmsAssets : this.assets;
      if (this.query.length > 0) {
        assets = assets.filter((asset) => {
          let resultFound = false;
          // Search Name
          if (
            this.query
              .toLowerCase()
              .split(' ')
              .every((v) => asset.name.toLowerCase().includes(v))
          ) {
            resultFound = true;
          }
          // Search Type
          if (
            this.query
              .toLowerCase()
              .split(' ')
              .every((v) => asset.assetType.name.toLowerCase().includes(v))
          ) {
            resultFound = true;
          }
          // Search Categories
          asset.assetCategories.forEach((category) => {
            if (
              this.query
                .toLowerCase()
                .split(' ')
                .every((v) => category.name.toLowerCase().includes(v))
            ) {
              resultFound = true;
            }
          });
          // Search Job Roles
          asset.assetJobRoleCategories.forEach((category) => {
            if (
              this.query
                .toLowerCase()
                .split(' ')
                .every((v) => category.name.toLowerCase().includes(v))
            ) {
              resultFound = true;
            }
          });
          // Search Description
          if (
            this.query
              .toLowerCase()
              .split(' ')
              .every((v) => asset.description.toLowerCase().includes(v))
          ) {
            resultFound = true;
          }
          // Search Model
          if (
            this.query
              .toLowerCase()
              .split(' ')
              .every((v) => asset.assetModelYear.modelName.toLowerCase().includes(v))
          ) {
            resultFound = true;
          }
          // Search Model Year
          if (
            this.query
              .toLowerCase()
              .split(' ')
              .every((v) => asset.assetModelYear.modelYear.toLowerCase().includes(v))
          ) {
            resultFound = true;
          }
          return resultFound;
        });
      }
      return assets;
    },
  },
};
</script>

<style lang="scss" scoped>
.Site-Navigation-Search-Results {
  display: block;
  width: 100%;
  max-height: 60vh;
  padding-right: 0.375rem; // 6px
  padding-left: 0.375rem; // 6px
  overflow-y: scroll;
  background: $white;
  @include bp-md-tablet {
    max-height: 85vh;
    padding-right: 2rem; // 32px
    padding-left: 2rem; // 32px
  }

  &__result,
  &__no-result {
    width: 100%;
    padding: 2rem 0; // 32px
    border-bottom: 2px solid $dividers;
    &:last-child {
      border-bottom: 0;
    }
    > a {
      display: block;
      color: $grey--dark;
      text-decoration: none;
    }
  }
  &__title {
    margin-bottom: 1rem;
    font-size: 0.875rem; // 14px;
    color: $grey--dark;
  }
  &__tags {
    display: flex;
    flex-direction: row;
  }
  &__tag {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-right: 0.5rem;
    font-size: 0.625rem; // 10px;
    background: $grey--soft;
  }
}
</style>
