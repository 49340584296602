import Vue from 'vue';
import { mapGetters } from 'vuex';
import ApiService from '@/services/api.service';
import Loading from 'vue-loading-overlay';
import VueMasonry from 'vue-masonry-css';
import App from './App.vue';
import router from './router';
import store from './store';
import titleMixin from './services/pageTitle.mixin';

Vue.config.productionTip = false;
Vue.mixin(titleMixin);
Vue.use(VueMasonry);

ApiService.init();

Vue.use(Loading, {
  color: '#000',
});

new Vue({
  router,
  store,
  beforeCreate() {
    // this.$store.commit('initialiseStore');
  },
  computed: {
    ...mapGetters(['userAccess']),
  },
  /* created() {
    axios.interceptors.request.use(
      (config) => {
        if (
          config.url.includes(process.env.VUE_APP_API_URL) &&
          this.userAccess &&
          typeof this.userAccess === 'string'
        ) {
          // If user access exists
          const jwt = this.userAccess;
          const jwtDecoded = jwt_decode(jwt);
          if (new Date() < new Date(jwtDecoded.exp * 1000)) {
            config.headers.Authorization = `${this.userAccess}`;
            ApiService.setToken(this.userAccess);
          } else {
            // Logout
            this.$store.dispatch('logoutUser');
            const loginUrl = 'https://vwgoalogin-audi.track360.com/';
            const currentUrl = window.location.href;
            window.location.href = `${loginUrl}?APP_ID=LEGACY&callback=${currentUrl}`;
          }
        } else {
          // Do something else
        }
        return config;
      },
      (err) => Promise.reject(err),
    );
  }, */
  render: (h) => h(App),
}).$mount('#app');
