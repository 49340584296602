<template>
  <button class="btn btn-download" @click="downloadResource(downloadLink)">
    <IconDownloadCircle v-if="isCircle" /><IconDownload v-else /><span :class="{ 'sr-only': isCircle }">Download</span>
  </button>
</template>

<script>
import axios from 'axios';
import IconDownloadCircle from '@/assets/icons/system_download.svg';
import IconDownload from '@/assets/icons/download_icon.svg';

export default {
  components: {
    IconDownloadCircle,
    IconDownload,
  },
  props: {
    downloadLink: {
      type: String,
      default: '',
    },
    isCircle: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    downloadResource(url) {
      axios
        .get(url, {
          crossdomain: true,
          responseType: 'blob',
        })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const filename = url.split('/').pop().split('?')[0];
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', filename);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error.response);
        });
    },
  },
};
</script>
