<template>
  <div :class="[container, { 'search-results-open': isSearchResultsOpen }]">
    <ul>
      <li>
        <div class="nav-item">
          <UserIcon class="nav-btn--icon" :class="`${container}__icon ${container}__icon--avatar`" />
          <span class="nav-btn--text" :class="`${container}__username`">{{ username }}</span>
        </div>
      </li>
      <li :class="{ active: currentRoutePath === '/favorites' }">
        <router-link to="/favorites" class="nav-btn">
          <StarIcon class="nav-btn--icon" :class="`${container}__icon ${container}__icon--favorites`" />
          <span class="nav-btn--text" :class="`${container}__favorites ${container}__button`">Favorites</span>
        </router-link>
      </li>
      <li>
        <button class="nav-btn" :class="`${container}__button ${container}__logout`" @click="logoutUser">
          <span :class="`${container}__logout__text`">Log Out</span>
          <LogoutIcon :class="`${container}__icon ${container}__icon--logout`" />
        </button>
      </li>
    </ul>
    <SiteNavigationSearch @open-search-dropdown="isSearchResultsOpen = !isSearchResultsOpen" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import LogoutIcon from '@/assets/icons/logout.svg';
import SiteNavigationSearch from '@/components/SiteNavigationSearch.vue';
import StarIcon from '@/assets/icons/favorite_icon.svg';
import UserIcon from '@/assets/icons/user_icon.svg';

export default {
  components: {
    LogoutIcon,
    SiteNavigationSearch,
    StarIcon,
    UserIcon,
  },
  data() {
    return {
      container: 'Site-Navigation-Actions',
      isSearchResultsOpen: false,
    };
  },
  computed: {
    ...mapState({
      searchActive: ({ search }) => search?.active,
    }),
    ...mapGetters(['user']),
    currentRoutePath() {
      return this.$route.path;
    },
    username() {
      return this.user?.uid;
    },
  },
  methods: {
    logoutUser() {
      this.$store.dispatch('logoutUser');
    },
  },
};
</script>

<style lang="scss">
.Site-Navigation-Actions {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  @include bp-lg-laptop {
    position: relative;
    width: 425px;
    padding-right: $desktop-search-btn-width;
    overflow-x: hidden;
  }
  @include bp-xl-desktop {
    width: 500px;
  }
  ul {
    display: flex;
    justify-content: flex-end;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  &__username {
    display: none;
    @include bp-xl-desktop {
      display: block;
      font-weight: bold;
    }
  }
  &__icon {
    &--logout {
      @include hide-on-desktop;
      @include bp-lg-laptop {
        display: block;
      }
      @include bp-xl-desktop {
        display: none;
      }
    }
  }
  &__favorites {
    @include hide-on-mobile;

    @include bp-lg-laptop {
      display: none;
    }
    @include bp-xl-desktop {
      display: block;
    }
  }
  &__logout {
    @include bp-lg-laptop {
      padding-right: calc(#{$search-button-width--desktop} + 1.5rem);
    }
  }
  &__logout__text {
    @include hide-on-mobile;
    @include bp-lg-laptop {
      display: none;
    }
    @include bp-xl-desktop {
      display: block;
    }
  }
  &.search-results-open {
    @include bp-lg-laptop {
      overflow-x: visible;
    }
  }
  > ul > li {
    &.active {
      background: $grey-background;
      a {
        color: $black;
      }
    }
  }
}
.nav-btn,
.nav-item {
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 0.4375rem; // 7px
  padding-left: 0.4375rem; // 7px
  font-family: $extended;
  font-size: 0.75rem; // 12px
  color: $black;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 1px solid transparent;
  @include bp-lg-laptop {
    padding-right: 0.9375rem; // 15px
    padding-left: 0.9375rem; // 15px
  }
  svg {
    width: 1.5rem; // 24px
    height: auto;
    @include bp-md-tablet {
      margin-right: 0.625rem; // 10px
    }
    @include bp-xl-desktop {
      margin-right: 0.875rem; // 14px
    }
    &.Site-Navigation-Actions__icon--avatar {
      margin-right: 0;
      @include bp-xl-desktop {
        margin-right: 0.875rem; // 14px
      }
    }
    &.Site-Navigation-Actions__icon--favorites {
      width: 1.415625rem; // 22.65px
    }
    &.Site-Navigation-Actions__icon--logout {
      width: 1.10375rem; // 17.66px
    }
  }
}
</style>
