// eslint-disable-next-line import/no-cycle
import ApiService from '@/services/api.service';

export default {
  state: {
    isFiltersSet: false,
    filters: [
      {
        title: 'Asset Type',
        isCheckbox: true,
        parameters: [
          {
            name: 'PDF',
            value: 'pdf',
            isActive: false,
          },
          {
            name: 'Video',
            value: 'video',
            isActive: false,
          },
          {
            name: 'Image',
            value: 'image',
            isActive: false,
          },
          {
            name: 'MP3',
            value: 'mp3',
            isActive: false,
          },
        ],
      },
      {
        title: 'Asset Category',
        isCheckbox: true,
        parameters: [
          {
            name: 'Audi Collection',
            value: 'audi-collection',
            isActive: false,
          },
          {
            name: 'Merchandising',
            value: 'merchandising',
            isActive: false,
          },
          {
            name: 'Sell Sheets',
            value: 'sell-sheets',
            isActive: false,
          },
          {
            name: 'Incentives',
            value: 'incentives',
            isActive: false,
          },
          {
            name: 'Product Information',
            value: 'product-information',
            isActive: false,
          },
          {
            name: 'Templates',
            value: 'templates',
            isActive: false,
          },
        ],
      },
      {
        title: 'Job Role',
        isCheckbox: true,
        parameters: [
          {
            name: 'Parts',
            value: 'parts',
            isActive: false,
          },
          {
            name: 'Warranty',
            value: 'warranty',
            isActive: false,
          },
          {
            name: 'Sales',
            value: 'sales',
            isActive: false,
          },
          {
            name: 'Unassigned',
            value: 'unassigned',
            isActive: false,
          },
          {
            name: 'Service',
            value: 'service',
            isActive: false,
          },
          {
            name: 'Technician',
            value: 'technician',
            isActive: false,
          },
        ],
      },
      {
        title: 'Model Year',
        isCheckbox: false,
        selectionText: 'Select a Model Year',
        parameters: [],
      },
      {
        title: 'Model',
        isCheckbox: false,
        selectionText: 'Select a Model',
        parameters: [],
      },
    ],
    defaultFilters: [],
    blankFilters: [],
  },
  getters: {
    filters: (state) => state.filters,
    defaultFilters: (state) => state.defaultFilters,
  },
  mutations: {
    SET_FILTERS: (state, { checkbox, parameterValue, filterTitle }) => {
      const filterIndex = state.filters.findIndex((filter) => filter.title === filterTitle);
      const parameterIndex = state.filters[filterIndex].parameters.findIndex(
        (parameter) => parameter.value === parameterValue,
      );
      state.filters[filterIndex].parameters[parameterIndex].isActive = checkbox.checked;
    },
    SET_FILTERS_DROPDOWN: (state, { parameterValue, filterTitle }) => {
      const filterIndex = state.filters.findIndex((filter) => filter.title === filterTitle);
      const parameterIndex = state.filters[filterIndex].parameters.findIndex(
        (parameter) => parameter.value === parameterValue,
      );
      state.filters[filterIndex].parameters.forEach((parameter) => {
        parameter.isActive = false;
      });
      if (parameterValue !== '') {
        state.filters[filterIndex].parameters[parameterIndex].isActive = true;
        state.filters[filterIndex].selectionText = state.filters[filterIndex].parameters[parameterIndex].name;
      } else {
        state.filters[filterIndex].selectionText = `Select a ${filterTitle}`;
      }
    },
    SET_TYPES: (state, filterTypes) => {
      const filterIndex = state.filters.findIndex((filter) => filter.title === 'Asset Type');
      if (filterIndex !== -1) {
        state.filters[filterIndex].parameters = filterTypes;
      }
    },
    SET_CATEGORIES: (state, filterCategories) => {
      const filterIndex = state.filters.findIndex((filter) => filter.title === 'Asset Category');
      if (filterIndex !== -1) {
        state.filters[filterIndex].parameters = filterCategories;
      }
    },
    SET_JOB_ROLES: (state, filterJobRoles) => {
      const filterIndex = state.filters.findIndex((filter) => filter.title === 'Job Role');
      if (filterIndex !== -1) {
        state.filters[filterIndex].parameters = filterJobRoles;
      }
    },
    SET_USER_JOB_ROLES: (state, userJobRoleId) => {
      // Set Users Job Role
      const filterIndex = state.filters.findIndex((filter) => filter.title === 'Job Role');
      if (filterIndex !== -1) {
        const parameterIndex = state.filters[filterIndex].parameters.findIndex(
          (parameter) => parameter.id === userJobRoleId,
        );
        state.filters[filterIndex].parameters[parameterIndex].isActive = true;
        // Set Unassigned Job Role
        const parameterUnassignedIndex = state.filters[filterIndex].parameters.findIndex(
          (parameter) => parameter.name === 'Unassigned',
        );
        state.filters[filterIndex].parameters[parameterUnassignedIndex].isActive = true;
      }
    },
    SET_YEARS: (state, filterYears) => {
      const filterIndex = state.filters.findIndex((filter) => filter.title === 'Model Year');
      if (filterIndex !== -1) {
        state.filters[filterIndex].parameters = filterYears;
      }
    },
    SET_MODELS: (state, filterModels) => {
      const filterIndex = state.filters.findIndex((filter) => filter.title === 'Model');
      if (filterIndex !== -1) {
        state.filters[filterIndex].parameters = filterModels;
      }
    },
    SET_DEFAULT_FILTERS: (state) => {
      state.defaultFilters = JSON.parse(JSON.stringify(state.filters));
    },
    SET_FILTERS_TO_DEFAULT: (state) => {
      state.filters = JSON.parse(JSON.stringify(state.defaultFilters));
    },
    SET_BLANK_FILTERS: (state) => {
      state.blankFilters = JSON.parse(JSON.stringify(state.filters));
    },
    SET_FILTERS_TO_BLANK: (state) => {
      if (state.blankFilters.length !== 0) {
        state.filters = JSON.parse(JSON.stringify(state.blankFilters));
      }
    },
    SET_IS_FILTERS_SET: (state, value) => {
      state.isFiltersSet = value;
    },
  },
  actions: {
    getConfigs({ state, commit, rootState }) {
      if (!state.isFiltersSet) {
        return ApiService.get('/config').then((response) => {
          // Load Types
          const filterTypes = [];
          response.data.data.assetType.forEach((type) => {
            const thisType = {};
            thisType.id = type.id;
            thisType.name = type.name;
            thisType.value = type.name.toLowerCase();
            thisType.isActive = false;
            filterTypes.push(thisType);
          });
          commit('SET_TYPES', filterTypes);
          // Load Categories
          const filterCategories = [];
          response.data.data.category.forEach((category) => {
            const thisCategory = {};
            thisCategory.id = category.id;
            thisCategory.name = category.name;
            thisCategory.value = category.name.toLowerCase();
            thisCategory.isActive = false;
            filterCategories.push(thisCategory);
          });
          commit('SET_CATEGORIES', filterCategories);
          commit('SET_CONFIG_CATEGORIES', response.data.data.category);
          // Load Job Roles
          const filterJobRoles = [];
          response.data.data.jobRole.forEach((role) => {
            const thisJobRole = {};
            thisJobRole.id = role.id;
            thisJobRole.name = role.name;
            thisJobRole.value = role.name.toLowerCase();
            thisJobRole.isActive = false;
            filterJobRoles.push(thisJobRole);
          });
          commit('SET_JOB_ROLES', filterJobRoles);
          commit('SET_CONFIG_JOB_ROLES', response.data.data.jobRole);
          // Load Years
          const filterYears = [
            {
              name: 'Reset',
              value: '',
              isActive: false,
            },
          ];
          const filterModels = [
            {
              name: 'Reset',
              value: '',
              isActive: false,
            },
          ];
          response.data.data.modelYear.forEach((modelYear) => {
            if (!filterYears.some((year) => year.name === modelYear.modelYear)) {
              const thisYear = {};
              thisYear.id = modelYear.id;
              thisYear.name = modelYear.modelYear;
              thisYear.value = modelYear.modelYear;
              thisYear.isActive = false;
              filterYears.push(thisYear);
            }
            if (!filterModels.some((model) => model.name === modelYear.modelName)) {
              const thisModel = {};
              thisModel.modelYearId = modelYear.id;
              thisModel.name = modelYear.modelName;
              thisModel.value = modelYear.modelName.toLowerCase();
              thisModel.isActive = false;
              filterModels.push(thisModel);
            }
          });
          filterYears.sort((a, b) => a.value - b.value);
          commit('SET_YEARS', filterYears);
          commit('SET_MODELS', filterModels);
          commit('SET_CONFIG_MODEL_YEARS', response.data.data.modelYear);
          commit('SET_CONFIG_DEPARTMENTS', response.data.data.feedbackDepartment);
          commit('SET_BLANK_FILTERS');
          // Add User Filters
          if (rootState.user && rootState.user.user) {
            commit('SET_USER_JOB_ROLES', rootState.user.user.jobRoleId);
            commit('SET_DEFAULT_FILTERS');
          }
          commit('SET_IS_FILTERS_SET', true);
        });
      }
      return Promise.resolve();
    },
  },
};
