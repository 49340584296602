<template>
  <div class="dropdown" v-on-clickaway="closeDropdown">
    <button type="button" @click="isOpen = !isOpen">
      {{ btnPreText }}{{ btnText }}<span v-if="btnPlaceholder && !btnText">{{ btnPlaceholder }}</span>
    </button>
    <ul class="dropdown-menu" :class="{ show: isOpen }">
      <li v-for="option in dropdownOptions" :key="option.value">
        <a href="#" :class="{ active: btnText === option.name }" @click.prevent="selectionClicked(option)">{{
          option.name
        }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
  mixins: [clickaway],
  props: {
    btnText: {
      type: String,
      default: 'dropdown',
    },
    btnPreText: {
      type: String,
      default: '',
    },
    btnPlaceholder: {
      type: String,
      default: '',
    },
    dropdownOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    closeDropdown() {
      if (this.isOpen === true) {
        this.isOpen = false;
      }
    },
    selectionClicked(option) {
      this.isOpen = false;
      this.$emit('selection-clicked', option);
      // this.btnText = option;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  button {
    position: relative;
    max-width: 200px;
    padding: 0 2.75rem 0.625rem 0; // 44px 10px
    overflow: hidden;
    color: $grey--dark;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: transparent;
    border-color: transparent transparent $grey--dark transparent;
    border-style: solid;
    border-width: 1px;
    @include bp-lg-laptop {
      max-width: none;
    }
    &::after {
      position: absolute;
      top: 0.125rem; // 2px
      right: 0.3125rem; // 5px
      width: 1em;
      height: 1em;
      line-height: 0;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
      transition: transform 0.35s ease;
      transform-origin: 0.5em 50%;
    }
    > span {
      color: $grey--medium;
    }
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    min-width: 100%;
    max-height: 200px;
    padding: 0.375rem 0; // 6px\
    margin: 0;
    overflow-y: scroll;
    color: $body-color;
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
    list-style: none;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $grey--light;
    border-radius: 0.25rem;
    > li > a {
      display: block;
      width: 100%;
      padding: 0.25rem 0.875rem; // 4px 14px
      clear: both;
      color: $grey--dark;
      text-decoration: none;
      white-space: nowrap;
      &:hover,
      &.active {
        color: $white;
        background-color: $grey--light;
      }
    }
  }
  .dropdown-menu.show {
    display: block;
  }
  &.is-invalid button {
    color: $error;
    border-bottom-color: $error;
    > span {
      color: $error;
    }
  }
}
</style>
