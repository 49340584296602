<template>
  <div :class="`${container} ${isActive ? container + '--active' : ''}`">
    <form method="post" v-on:submit.prevent="search">
      <button type="button" :class="`${container}__button`" @click="toggleSearch"><SearchIcon /></button>
      <input v-if="isActive" :class="`${container}__input`" type="text" placeholder="Search" v-model="query" />
    </form>
    <SiteNavigationSearchResults v-if="isResultsActive" v-show="isActive" :query="query" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SearchIcon from '@/assets/icons/search_icon.svg';
import SiteNavigationSearchResults from '@/components/SiteNavigationSearchResults.vue';

export default {
  components: {
    SearchIcon,
    SiteNavigationSearchResults,
  },
  data() {
    return {
      container: 'Site-Navigation-Search',
      searchToggleClicks: 0,
      isActive: false,
      isResultsActive: false,
      query: '',
    };
  },
  computed: {
    ...mapState({
      active: ({ search }) => search.active,
    }),
  },
  watch: {
    query(newQuery) {
      this.search(newQuery);
    },
    $route() {
      this.query = '';
    },
  },
  methods: {
    toggleSearch() {
      this.isActive = !this.isActive;
      this.$emit('open-search-dropdown');
    },
    search(query) {
      if (this.isActive && query.length > 0) {
        this.$store.commit('openSearchDropDown');
        this.isResultsActive = true;
      } else {
        this.isResultsActive = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Site-Navigation-Search {
  // right: 0;
  left: 100%;
  transition: all 0.3s ease;
  @include bp-lg-laptop {
    position: absolute;
    right: 0;
    // left: auto;
    left: calc(100% - #{$desktop-search-btn-width});
    height: 100%;
  }
  form {
    display: flex;
    width: 100%;
    height: 100%;
    @include bp-lg-laptop {
      align-items: center;
      overflow: hidden;
      border-left: 1px solid $grey--medium;
    }
  }
  button {
    height: calc(100% - 0.875rem);
    padding-top: 0.875rem; // 14px
    padding-right: 0.953125rem; // 15px
    padding-left: 0.4375rem; // 7px
    background-color: transparent;
    border: 1px solid transparent;
    @include bp-lg-laptop {
      width: ($desktop-search-btn-width - 1);
      height: 100%;
      padding: 1.6875rem 1.875rem; // 27px 30px
    }
    svg {
      width: 1.219375rem; // 19.51px
      height: auto;
    }
  }
  &--active {
    position: absolute;
    left: 0;
    z-index: 999;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-right: 0.875rem; // 14px
    padding-left: 0.875rem; // 14px
    background: $white;
    @include bp-lg-laptop {
      max-width: 425px;
      padding-right: 0;
      padding-left: 0;
    }
  }
  .Site-Navigation-Search__input {
    position: relative;
    display: block;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    padding: 0.75rem 0 0.5rem 0;
    margin-left: 25px;
    font-size: 0.75rem; // 12px;
    line-height: 0.75rem; // 12px;
    border-color: $grey--warm;
    border-style: solid;
    border-width: 0 0 2px 0;
  }
}
</style>
