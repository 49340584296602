// eslint-disable-next-line
import ApiService from '@/services/api.service';

export default {
  state: {
    enums: null,
  },
  getters: {
    enums: (state) => state.enums,
  },
  actions: {
    getEnums({ state, commit }) {
      if (!state.enums) {
        ApiService.get('/enums').then(({ data }) => {
          commit('SET_ENUMS', data?.data);
        });
      }
    },
  },
  mutations: {
    SET_ENUMS: (state, enums) => {
      state.enums = enums;
    },
  },
};
