<template>
  <nav class="Site-Navigation">
    <router-link to="/">
      <Logo class="Site-Navigation__logo" />
    </router-link>
    <SiteNavigationActions />
    <SiteNavigationLinks />
  </nav>
</template>

<script>
import Logo from '@/assets/icons/logo.svg';
import SiteNavigationActions from '@/components/SiteNavigationActions.vue';
import SiteNavigationLinks from '@/components/SiteNavigationLinks.vue';

export default {
  components: {
    Logo,
    SiteNavigationActions,
    SiteNavigationLinks,
  },
};
</script>

<style lang="scss" scoped>
.Site-Navigation {
  display: flex;
  flex-wrap: wrap;
  @include bp-lg-laptop {
    flex-wrap: nowrap;
    align-items: stretch;
  }
  &-Links {
    @include bp-lg-laptop {
      order: 2;
    }
  }
  &-Actions {
    @include bp-lg-laptop {
      order: 3;
    }
  }
  &__logo {
    width: 182px;
    margin: 0.875rem 0 0.875rem 0.875rem; // 14px;
    @include bp-lg-laptop {
      width: 277px;
      margin: 1.25rem 2.8125rem 1.5625rem 1.25rem; // 20px 45px 25px 20px
    }
  }
}
</style>
