<template>
  <button class="btn btn-primary" @click="clicked">Filters<i class="plus"></i></button>
</template>

<script>
export default {
  methods: {
    clicked() {
      this.$emit('button-clicked');
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 100%;
  margin-top: 1.875rem; // 30px
  margin-bottom: 1.875rem; // 30px
}
.plus {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-left: 0.625rem; // 10px
  font-size: 75%;
  font-style: inherit;
  color: inherit;
}
.plus::before,
.plus::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  content: '';
  background-color: currentColor;
  transform: translate(-50%, -50%);
}
.plus::before {
  width: 0.1em;
  height: 1em;
}

.plus::after {
  width: 1em;
  height: 0.1em;
}
</style>
